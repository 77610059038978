<template>
  <div>
    <AddTextModuleModal
      :show="showAddModal"
      @save="fetchData"
      @close="showAddModal = false"
    />
    <EditTextModuleModal
      :show="showEditModal"
      :edit-id="editId"
      :form-check="false"
      @close="showEditModal = false"
    />
    <div class="card-header bg-white d-flex justify-content-between">
      <button
        class="btn btn-primary"
        @click="showAddModal = true"
      >
        Baustein anlegen
      </button>
      <BasicInput
        v-model="search"
        :columns="{label: ['col'], input: ['col-12']}"
        :horizontal="true"
        :margin="false"
        placeholder="Suche"
      >
        <template #append>
          <button
            class="btn btn-white border"
            @click="searchModules"
          >
            <i class="far fa-search" />
          </button>
        </template>
      </BasicInput>
    </div>
    <div
      class="card-body d-flex justify-content-center"
    >
      <div class="row d-flex w-100 h-100">
        <div class="col-12 w-100 h-100">
          <div
            v-if="modules && modules.length > 0"
          >
            <transition-group
              name="slide-fade"
              class="row"
            >
              <div
                v-for="item in modules"
                :key="item.name"
                class="col-3 mb-4"
              >
                <TextModule
                  :name="item.name"
                  :title="item.symbol ? item.symbol + ' ' + item.title : item.title"
                  :text="item.text"
                  :short-text="true"
                  :show-add="false"
                  @edit="editModule(item._id)"
                />
              </div>
            </transition-group>
          </div>
          <div
            v-if="search.length <= 0 && modules.length < total"
            class="d-flex justify-content-center w-100 py-2"
          >
            <button
              class="btn bg-transparent text-primary"
              @click="loadMore"
            >
              Mehr anzeigen...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import TextModule from './TextModule.vue'
import AddTextModuleModal from '@/components/Modals/AddTextModuleModal.vue'
import EditTextModuleModal from '@/components/Modals/EditTextModuleModal.vue'

export default {
  components: {
    BasicInput,
    TextModule,
    AddTextModuleModal,
    EditTextModuleModal
  },
  data () {
    return {
      search: '',
      modules: [],
      editId: null,
      showAddModal: false,
      showEditModal: false,
      elementsLoaded: 0,
      total: 0
    }
  },
  watch: {
    search: {
      handler () {
        this.searchModules()
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const modules = await this.$store.dispatch('agb-module/find', {
          query: {
            $limit: 25
          }
        })
        this.total = modules.total
        this.modules = modules.data
        this.elementsLoaded = this.modules.length
      } catch (e) {
        console.error(e)
      }
    },
    editModule (id) {
      this.editId = id
      this.showEditModal = true
    },
    async loadMore () {
      try {
        const modules = (await this.$store.dispatch('agb-module/find', {
          query: {
            $skip: this.elementsLoaded,
            $limit: 25
          }
        }))
        this.modules.push(...(modules.data))
        this.total = modules.total
        this.elementsLoaded += modules.data.length
      } catch (error) {
        console.error(error)
      }
    },
    async searchModules () {
      if (this.search.length > 0) {
        this.modules = (await this.$store.dispatch('agb-module/find', {
          query: {
            $search: this.search
          }
        })).data
      } else {
        this.fetchData()
      }
    }
  }
}
</script>
