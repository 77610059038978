<template>
  <div>
    <ca-header
      heading="Garantiebedingungen"
      :column-layout="true"
    >
      <template #alert>
        <ca-alert
          v-if="uploadSuccess"
          variant="success"
          :message="uploadSuccess"
          @close="uploadSuccess = null"
        />
      </template>
      <template #buttonSpace>
        <button
          v-if="me.role === 10"
          class="mb-0 btn btn-primary float-sm-right"
          @click="$router.push({ name: 'AgbEditorNew' })"
        >
          Garantiebedingung hinzufügen
        </button>
      </template>
    </ca-header>
    <div>
      <b-tabs card>
        <b-tab
          title="Dokumente"
          active
          no-body
        >
          <div class="card-body">
            <div class="row row-eq-height">
              <div class="col-8">
                <data-table
                  ref="agbTable"
                  :fields="fields"
                  service="agbs"
                  title="Garantiebedingungen"
                  :base-query="baseQuery"
                  @row-clicked="(item) => reloadPreview(item)"
                >
                  <template
                    slot="download"
                    slot-scope="{item: agb}"
                  >
                    <SignedDownloadLink
                      v-if="agb.file"
                      ref="pdfLink"
                      :file="agb.file"
                    >
                      <template #default="{link}">
                        <a
                          title="GB herunterladen"
                          class="btn btn-primary btn-sm float-sm-right text-white"
                          :download="agb.file.name"
                          :href="link"
                        >
                          <i class="fas fa-download fa-fw" />
                        </a>
                      </template>
                    </SignedDownloadLink>
                  </template>
                  <template
                    slot="edit"
                    slot-scope="{item: agb}"
                  >
                    <button
                      title="GB bearbeiten"
                      class="btn btn-primary btn-sm float-sm-right text-white"
                      @click="$router.push({name: 'AgbEditorEdit', params: {agbId: agb._id}})"
                    >
                      <i class="fas fa-edit fa-fw" />
                    </button>
                  </template>
                </data-table>
              </div>
              <div class="col-4">
                <div class="card h-100">
                  <div
                    v-if="!selectedDoc"
                    class="d-flex h-100 flex-column align-items-center justify-content-center my-4"
                  >
                    <i class="fad fa-mouse-pointer fa-4x mb-3 text-primary" />
                    <h5 class="m-0">
                      Keine Garantiebedingung ausgewählt
                    </h5>
                  </div>
                  <template v-else>
                    <div class="card-header bg-white">
                      <div class="d-flex my-2 align-items-center justify-content-between">
                        <h4 class="m-0 preview-title">
                          {{ selectedDoc.file.name }}
                        </h4>
                        <div style="height: 38px" />
                      </div>
                    </div>
                    <div class="overflow-hidden">
                      <div class="preview">
                        <div v-if="!previewLoading">
                          <PdfPreview
                            v-if="previewData"
                            :data="previewData"
                            :scale="getScale"
                          />
                        </div>
                        <div
                          v-else
                          class="h-100 d-flex justify-content-center align-items-center"
                        >
                          <i class="fad fa-spinner-third fa-spin fa-3x text-primary mr-3" />
                          <h5 class="m-0">
                            Lade Vorschau...
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="card-body flex-grow-0">
                      <div class="row mb-3">
                        <div class="col-6">
                          Status
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                          {{ agbStatus[selectedDoc.status].human }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          Veröffentlichung
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                          {{ formatDate(selectedDoc.dateTime, 'DD.MMM YYYY') || '-' }}
                        </div>
                      </div>
                    </div>
                    <div class="card-header border-top bg-white">
                      <h5 class="m-0">
                        Versionsverlauf
                      </h5>
                    </div>
                    <div
                      v-if="logList && logList.length > 0"
                      class="log-list"
                    >
                      <div
                        v-for="(item, index) in logList"
                        :key="index"
                        class="d-flex list-item border-bottom py-2 w-100"
                      >
                        <div
                          class="col-12 d-flex flex-column justify-content-center align-items-start"
                        >
                          <small><b>{{ formatDate(item.dateTime, 'DD.MMM YYYY HH:mm') }} Uhr - {{ `${item.user.firstname} ${item.user.lastname}` }}</b></small>
                          {{ item.text }}
                        </div>
                      </div>
                      <div
                        v-if="logList.length >= 10"
                        class="d-flex justify-content-center w-100 py-2"
                      >
                        <button
                          class="btn bg-transparent text-primary"
                          @click="loadMore"
                        >
                          Mehr anzeigen...
                        </button>
                      </div>
                    </div>
                    <div
                      v-else
                      class="card-body flex-grow-0 d-flex justify-content-center py-3 w-100"
                    >
                      Keine Einträge vorhanden
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab
          title="Bausteine"
          no-body
        >
          <ModuleEditor />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import SignedDownloadLink from '@/components/SignedDownloadLink.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import formatDate from '../../filters/formatDate.js'
import formatAgbType from '../../filters/formatAgbType.js'
import feathers from '../../api'
import axios from 'axios'
import PdfPreview from '@/components/PdfPreview.vue'
import agbStatus from '@/resources/enums/agbStatus.js'
import ModuleEditor from './components/ModuleEditor.vue'

export default {
  name: 'AgbList',
  components: {
    DataTable,
    CaHeader,
    SignedDownloadLink,
    PdfPreview,
    ModuleEditor
  },
  data: () => ({
    fields: [
      {
        label: 'Dateiname',
        key: 'file.name'
      },
      {
        label: 'Typ',
        key: 'type',
        formatter: value => formatAgbType(value),
        filter: {
          type: 'LIST',
          fetchOptions: () => ([
            { value: 'GARANTIE' },
            { value: 'KAUFSCHUTZ' }
          ])
        }
      },
      {
        label: 'Erstellt am',
        key: 'createdAt',
        formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
        filter: {
          type: 'DATERANGE'
        }
      },
      {
        label: '',
        key: 'download'
      },
      {
        label: '',
        key: 'edit'
      }
    ],
    selectedDoc: null,
    uploadError: null,
    uploadSuccess: null,
    showAgbUpload: false,
    previewLoading: false,
    previewData: null,
    logList: [],
    formatDate,
    agbStatus
  }),
  computed: {
    userId () {
      const userIdFromParams = this.$route.params.userId
      if (!userIdFromParams) return
      if (userIdFromParams === 'me') {
        return this.$store.getters['auth/user'].user._id
      } else {
        return userIdFromParams
      }
    },
    orderId () {
      const orderId = this.$route.params.orderId
      return orderId
    },
    baseQuery () {
      return {
        userId: this.userId,
        orderId: this.orderId
      }
    },
    getScale () {
      if (window.innerWidth > 2150) {
        return 1
      } else if (window.innerWidth > 1750) {
        return 1.5
      } else if (window.innerWidth > 1150) {
        return 0.75
      } else {
        return 0.5
      }
    },
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  methods: {
    agbUploadClose () {
      this.showAgbUpload = false
      this.$refs.agbTable.fetchData()
    },
    async replaceAgbFile (agb, event) {
      try {
        if (agb.uploadPending || !event.target.files[0]) return
        this.$set(agb, 'uploadPending', true)
        const toUploadFile = event.target.files[0]
        const file = await this.uploadFile(toUploadFile)
        await this.$store.dispatch('agbs/patch', [agb._id, {
          name: file.name,
          fileId: file._id
        }])
        console.log('done')
        agb.file = file
        this.uploadSuccess = `Die Datei "${agb.name}" wurde erfolgreich durch "${file.name}" ersetzt`
      } catch (error) {
        console.log(error)
        this.uploadError = error
      } finally {
        agb.uploadPending = false
      }
    },
    async uploadFile (toUploadFile) {
      const file = await feathers.service('file').create({
        name: toUploadFile.name,
        mimeType: toUploadFile.type
      })
      await axios.put(file.uploadUrl, toUploadFile, this.config)
      this.uploadSuccess = `Die Datei "${file.name}" wurde erfolgreich hochgeladen`
      return file
    },
    async reloadPreview (item) {
      console.log(item)
      if (item !== this.selectedDoc) {
        this.previewLoading = true
        this.previewData = null
        try {
          const logs = await this.$store.dispatch('agb-log/find', {
            query: {
              agbId: item._id
            }
          })
          const res = await axios.get(item.file.downloadUrl, {
            responseType: 'arraybuffer'
          })
          this.logList = logs.data
          this.elementsLoaded = this.logList.length
          this.previewData = await res.data
        } catch (e) {
          console.error(e)
        } finally {
          if (this.previewData) {
            this.previewLoading = false
            this.selectedDoc = item
          }
        }
      }
    },
    async loadMore () {
      try {
        const loglist = (await this.$store.dispatch('agb-log/find', {
          query: {
            agbId: this.selectedDoc._id,
            $skip: this.elementsLoaded,
            $limit: 10,
            $sort: {
              createdAt: -1
            }
          }
        }))
        this.logList.unshift(...(loglist.data.reverse()))
        this.elementsLoaded += loglist.data.length
      } catch (error) {
        console.error(error)
      }
    },
    formatProductName (product) {
      let result = `${product.hn} - ${product.name}`
      if (product.type) result = `${product.type} - ${result}`
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
  .space-header {
    margin-top: -67px;
    padding-top: 67px;
  }

  .preview {
    width: 100%;
    height: 500px;
    background-color: $gray-2;
    &>div {
      height: 100%;
    }
  }

  .preview-title {
    max-width: 550px;
  }

  .list-item {
    width: 100%;
    display: flex;
    &>.col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    &>.col:last-child {
      align-items: flex-end;
    }
  }

  .tabs::v-deep{
    height: 100vh;
    background-color: white;
    margin-top: -68px;
    padding-top: 68px;
    margin-bottom: -51px;
    padding-bottom: 51px;
    display: flex;
    flex-direction: column;
    .card-header{
      flex-shrink: 0;
    }
    .tab-content{
      background-color: white;
      flex-grow: 1;
      overflow-y: scroll;
    }
  }
</style>
